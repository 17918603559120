import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineNotifyTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_line_notify_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineNotifyTemplateOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/line_notify_template_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
